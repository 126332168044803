import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.sass']
})
export class CarouselComponent implements OnInit {

  @Output() eventClickedImg: EventEmitter<[string, string, string]> = new EventEmitter<[string, string, string]>();

  slides = [
    // Bilddatei, Unterschrift, ,Aktivität, POI-Kategorie, POI-Id
    ['DadiLiner.jpg', 'DadiLiner', '', '', ''],
    ['Darmstadt.jpg', 'Darmstadt', '', ''],
    ['Entspannt_in_den_Herbst.jpg', 'Entspannt in den Herbst', 'POI', '10001', 'POI_10129'],
    ['Radtour_mit_dem_Gravelbike.jpg', 'Radtour mit dem Gravelbike', '', '', ''],
    ['Wandern.jpg', 'Wandern', '', '', '']
  ];
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    infinite: true
  };

    constructor() { }

  ngOnInit(): void {
  }

  clickImg(activity: string, kat: string, id: string): void {
    this.eventClickedImg.emit([activity, kat, id]);
  }

  breakpoint($event) {}

}
