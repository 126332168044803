import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IClickButtonEvent } from '../../interface/IClickButtonEvent';
import { IOptionGroup } from '../../interface/IOptionGroup';
import { IChangeCheckboxEvent } from '../../interface/IChangeCheckboxEvent';
import {DadiLinerSheet} from '../popups/popups.component';

@Component({
  selector: 'app-button-bar',
  templateUrl: './button-bar.component.html',
  styleUrls: ['./button-bar.component.sass']
})

export class ButtonBarComponent implements OnInit {

  @Output() eventClickedButton: EventEmitter<IClickButtonEvent> = new EventEmitter<IClickButtonEvent>();
  @Output() eventChangeCheckbox: EventEmitter<IChangeCheckboxEvent> = new EventEmitter<IChangeCheckboxEvent>();
  grayscale: boolean[] = [true, true, true, true]; // hst, rad, wandern poi
  grayscaleCount = 4;

  optionRad1: IOptionGroup = {
    label: 'Radwege', cssClass: 'optionSubGroup',
    details: [{ group: 'rww', id: 'RWW/RWW_10001', label: 'R4 Hessischer Radfernweg Nord-Süd', color: '#ff0000', weight: '4', index: 1, additional: false }]
  };
  optionRad2: IOptionGroup = {
    label: 'Tourenvorschläge', cssClass: 'optionSubGroupTour',
    details: [{ group: 'tvr', id: 'TVR/TVR_10001', label: 'Radeln in Familie', color: '#d36f1e', weight: '4', dashArray: '10, 5', index: 2, additional: true },
    { group: 'tvr', id: 'TVR/TVR_10002', label:'Radeln für Einsteiger', color: '#007bff', weight: '4', dashArray: '10, 5', index: 3, additional: true },
    { group: 'tvr', id: 'TVR/TVR_10003', label:'2-Tagestour', color: '#28a745', weight: '4', dashArray: '10, 5', index: 4, additional: true },
    { group: 'tvr', id: 'TVR/TVR_10005', label:'Radtour durch den Bachgau', color: '#d157be', weight: '4', dashArray: '10, 5', index: 5, additional: true },
    { group: 'tvr', id: 'TVR/TVR_10006', label:'Radtour Heubacher Wald', color: '#ff6767', weight: '4', dashArray: '10, 5', index: 5, additional: true },
    { group: 'tvr', id: 'TVR/TVR_10007', label:'Radtour entspannt in den Herbst', color: '#6610f2', weight: '4', dashArray: '10, 5', index: 5, additional: true },
    { group: 'tvr', id: 'TVR/TVR_10008', label:'Radtour mit dem Gravelbike', color: '#fd7e14', weight: '4', dashArray: '10, 5', index: 5, additional: true }]
  };
  menueOptionRad: IOptionGroup[] = [this.optionRad1, this.optionRad2];

  optionWandern1: IOptionGroup = {
    label: 'Wanderwege', cssClass: 'optionSubGroup',
    details: [{ group: 'waw', id: 'WAW/WAW_10001', label: 'Hessenweg 2', color: '#ff0000', weight: '4', index: 1, additional: false },
    { group: 'waw', id: 'WAW/WAW_10002', label: 'Illinois Wanderweg', color: '#ee0000', weight: '4', index: 2, additional: false },
    { group: 'waw', id: 'WAW/WAW_10003', label: 'Odenwälder Weininsel Wanderweg', color: '#dd0000', weight: '3', index: 3, additional: false },
    { group: 'waw', id: 'WAW/WAW_10004', label: 'Storchenweg', color: '#bb0000', weight: '4', index: 4, additional: false },
    { group: 'waw', id: 'WAW/WAW_10005', label: 'Umstädter Panoramarunde', color: '#aa0000', weight: '4', index: 5, additional: false },
    { group: 'waw', id: 'WAW/WAW_10006', label: 'Alemannenweg', color: '#990000', weight: '4', index: 6, additional: false },
    { group: 'waw', id: 'WAW/WAW_10007', label: 'Zwei-Burgen-Wanderweg', color: '#880000', weight: '4', index: 7, additional: false }]
  };
  optionWandern2: IOptionGroup = {
    label: 'Tourenvorschläge', cssClass: 'optionSubGroupTour',
    details: [{ group: 'tvw', id: 'TVW/TVW_10001', label: 'Dieburg entdecken', color: '#008dd2', weight: '4', dashArray: '1, 5', index: 9, additional: true },
    { group: 'tvw', id: 'TVW/TVW_10002', label: 'Erlebnis für Groß & Klein', color: '#cb5499', weight: '4', dashArray: '1, 5', index: 10, additional: true },
    { group: 'tvw', id: 'TVW/TVW_10003', label: 'Freizeitspaß & Adrenalin', color: '#fe556f', weight: '4', dashArray: '1, 5', index: 11, additional: true },
    { group: 'tvw', id: 'TVW/TVW_10004', label: 'Gemeinsam Knei(p)pen', color: '#6610f2', weight: '4', dashArray: '1, 5', index: 12, additional: true },
    { group: 'tvw', id: 'TVW/TVW_10005', label: 'Natur pur', color: '#07803c', weight: '4', dashArray: '1, 5', index: 13, additional: true },
    { group: 'tvw', id: 'TVW/TVW_10006', label: 'Tour für Genusswanderer', color: '#57a7b3', weight: '4', dashArray: '1, 5', index: 14, additional: true },
    { group: 'tvw', id: 'TVW/TVW_10007', label: 'Dieburg barrierefrei', color: '#ae3b10', weight: '4', dashArray: '1, 5', index: 15, additional: true },
    { group: 'tvw', id: 'TVW/TVW_10008', label: 'Spaziergang für Senioren', color: '#dc3545', weight: '4', dashArray: '1, 5', index: 16, additional: true },
    { group: 'tvw', id: 'TVW/TVW_10009', label: 'Wassertreten', color: '#20c997', weight: '4', dashArray: '1, 5', index: 17, additional: true },
    { group: 'tvw', id: 'TVW/TVW_10010', label: 'Wichtelwanderung', color: '#fd7e14', weight: '4', dashArray: '1, 5', index: 18, additional: true },
    { group: 'tvw', id: 'TVW/TVW_10011', label: 'Regionaler Genuss', color: '#000000', weight: '4', dashArray: '1, 5', index: 19, additional: true },
    { group: 'tvw', id: 'TVW/TVW_10012', label: 'Ab ins Grüne', color: '#ae3b10', weight: '4', dashArray: '1, 5', index: 20, additional: true },
    { group: 'tvw', id: 'TVW/TVW_10013', label: 'Spielplätze', color: '#000000', weight: '4', dashArray: '1, 5', index: 21, additional: true },
    { group: 'tvw', id: 'TVW/TVW_10014', label: 'Ab ins kühle Nass', color: '#000000', weight: '4', dashArray: '1, 5', index: 22, additional: true },
    { group: 'tvw', id: 'TVW/TVW_10015', label: 'Camping & Caravaning', color: '#000000', weight: '4', dashArray: '1, 5', index: 23, additional: true },
    { group: 'tvw', id: 'TVW/TVW_10016', label: 'Kino & Theater', color: '#000000', weight: '4', dashArray: '1, 5', index: 23, additional: true }]
  };
  menueOptionWandern: IOptionGroup[] = [this.optionWandern1, this.optionWandern2];

  optionPoi1: IOptionGroup = {
    label: 'Kategorien', cssClass: 'optionSubGroupSolo',
    details: [{ group: 'poi', id: 'POI/POI_10001', label: 'Burgen und Schlösser', icon: 'DADINA_Icon_Burg', index: 1, additional: false },
    { group: 'poi', id: 'POI/POI_10002', label: 'Freizeit', icon: 'DADINA_Icon_Freizeit', index: 2, additional: false },
    { group: 'poi', id: 'POI/POI_10003', label: 'Gastronomie', icon: 'DADINA_Icon_Gastro', index: 3, additional: false },
    { group: 'poi', id: 'POI/POI_10004', label: 'Kultur', icon: 'DADINA_Icon_Kultur', index: 4, additional: false },
    { group: 'poi', id: 'POI/POI_10005', label: 'Naturdenkmal', icon: 'DADINA_Icon_Natur', index: 5, additional: false },
    { group: 'poi', id: 'POI/POI_10006', label: 'Unterkunft', icon: 'DADINA_Icon_Unterkunft', index: 6, additional: false },
    { group: 'poi', id: 'POI/POI_10007', label: 'Regionaler Genuss', icon: 'DADINA_Icon_Genuss', index: 7, additional: false },
    { group: 'poi', id: 'POI/POI_10008', label: 'Spielplätze', icon: 'DADINA_Icon_Spielplatz', index: 8, additional: false },
    { group: 'poi', id: 'POI/POI_10009', label: 'Hallen- & Freibäder', icon: 'DADINA_Icon_Bad', index: 9, additional: false },
    { group: 'poi', id: 'POI/POI_10010', label: 'Camping', icon: 'DADINA_Icon_Camping', index: 10, additional: false },
    { group: 'poi', id: 'POI/POI_10011', label: 'Caravaning', icon: 'DADINA_Icon_Caravaning', index: 11, additional: false }]
  };
  menueOptionPoi: IOptionGroup[] = [this.optionPoi1];

  constructor(public dialog: MatDialog) { }

  ngOnInit() { }

  clickButtonLine(btn: string): void {
    this.hideContainer();
    const btnline: string[] = ['gu1', 'gu2', 'gu3', 'gu4'];
    let element;
    let img;
    btnline.forEach(item => {
      if (item === btn) {
         element = document.getElementById(btn);
         img = element.getAttribute('src').replace('.png', '');
         if (img.includes('gray')) {
          element.setAttribute('src', img.replace('_gray', '') + '.png');
        } else {
          element.setAttribute('src', img + '_gray.png');
        }
      } else {
        element = document.getElementById(item);
        img = element.getAttribute('src').replace('.png', '');
        if (!img.includes('gray')) {
          element.setAttribute('src', img + '_gray.png');
        }
      }
    });
    const event: IClickButtonEvent = {
      group: 'line'
    };
    switch (btn) {
      case 'gu1': event.id = btn; event.index = 0; break;
      case 'gu2': event.id = btn; event.index = 1; break;
      case 'gu3': event.id = btn; event.index = 2; break;
      case 'gu4': event.id = btn; event.index = 3; break;
    }
    this.eventClickedButton.emit(event);
  }

  public clickButton(btn: string): void {
    this.hideContainer();
    const event: IClickButtonEvent = {};
    switch (btn) {
      case 'hst': this.grayscale[0] = !this.grayscale[0]; this.setEvent(btn, event); break;
      // case 'rad': this.grayscale[1] = !this.grayscale[1]; this.setEvent(btn, event); break;
      // case 'wandern': this.grayscale[2] = !this.grayscale[2]; this.setEvent(btn, event); break;
      // case 'poi': this.grayscale[3] = !this.grayscale[3]; this.setEvent(btn, event); break;
      case 'rad': {
        this.grayscale[1] = !this.grayscale[1];
        this.grayscale[2] = true;
        this.grayscale[3] = true;
        break;
      }
      case 'wandern': {
        this.grayscale[1] = true;
        this.grayscale[2] = !this.grayscale[2];
        this.grayscale[3] = true;
        break;
      }
      case 'poi': {
        this.grayscale[1] = true;
        this.grayscale[2] = true;
        this.grayscale[3] = !this.grayscale[3];
        break;
      }
      case '': { // Klick auf Kreuz
        this.grayscale[1] = true;
        this.grayscale[2] = true;
        this.grayscale[3] = true;
        break;
      }

    }
    this.eventClickedButton.emit(event);
  }

  private setEvent(btn: string, event: IClickButtonEvent) {
    event.group = btn;
  }

  setGrayscale(index: number, grayscale: boolean): void {
    for (let i = 1; i < this.grayscaleCount; i++) {
      this.grayscale[i] = true;
      this.grayscale[index] = grayscale;
    }
  }

  public updateChangeCheckbox(event: IChangeCheckboxEvent): void {
    this.eventChangeCheckbox.emit(event);
  }

  hideContainer(): void {
    document.getElementById('container-buttonbar1').style.visibility = 'hidden';
    document.getElementById('container-carousel').style.visibility = 'hidden';
  }

  openDadiLiner(): void {
    const dialogRef3 = this.dialog.open(DadiLinerSheet, {
      height: '80%',
      width: '54%',
      panelClass: 'dadiliner-modalbox'
    });
    dialogRef3.afterClosed().subscribe(result => {
    });
  }
 }

