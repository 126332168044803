import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MatomoInjector } from 'ngx-matomo';
import { MatomoTracker } from 'ngx-matomo';
import { HttpClient } from '@angular/common/http';
import { IClickButtonEvent } from './interface/IClickButtonEvent';
import { IChangeCheckboxEvent } from './interface/IChangeCheckboxEvent';

import 'leaflet';

declare let L;
declare const is: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})

export class AppComponent implements OnInit {

  // tslint:disable-next-line:max-line-length
  constructor(private matomoInjector: MatomoInjector, private matomoTracker: MatomoTracker, private http: HttpClient, private route: ActivatedRoute, private meta: Meta) {
    this.mapIcon.set('DADINA_Icon_Burg', this.iconBurg);
    this.mapIcon.set('DADINA_Icon_Freizeit', this.iconFreizeit);
    this.mapIcon.set('DADINA_Icon_Gastro', this.iconGastro);
    this.mapIcon.set('DADINA_Icon_Kultur', this.iconKultur);
    this.mapIcon.set('DADINA_Icon_Natur', this.iconNatur);
    this.mapIcon.set('DADINA_Icon_Unterkunft', this.iconUnterkunft);
    this.mapIcon.set('DADINA_Icon_Genuss', this.iconGenuss);
    this.mapIcon.set('DADINA_Icon_Spielplatz', this.iconSpielplatz);
    this.mapIcon.set('DADINA_Icon_Bad', this.iconBad);
    this.mapIcon.set('DADINA_Icon_Camping', this.iconCamping);
    this.mapIcon.set('DADINA_Icon_Caravaning', this.iconCaravaning);

    this.matomoInjector.init('analytics/', 1);

    this.meta.addTags([
      { name: 'description', content: 'Mit Bus und Bahn Freizeittouren im Landkreis Darmstadt-Dieburg erleben' },
      // tslint:disable-next-line:max-line-length
      { name: 'keywords', content: 'Interaktiv, Freizeittouren, Freizeit, Wandern, Darmstadt-Dieburg, Fahrradfahren, Bus, ÖPNV, Burgen, Schlösser, Naturdenkmäler,	Rhein-Main-Region, Südhessen, Odenwald, Groß-Umstadt, Dieburg, Schaafheim, Otzberg, GU1, GU2, GU3, GU4, Illinois-Wanderweg, Odenwälder Weininsel, Storchenweg, Alemannenweg, Hessenweg, Panoramarundweg, Natur, Kneipen, Senioren, Familien, Gruberhof, Kapuzinerkloster'}
    ]);

  }
  title = 'DadinaMap';
  public map: L.Map;
  countLine = 4;
  // countRad = 2;
  // countWandern = 7;
  // countPoi = 6;
  layerLine: any[] = [];
  layerLineToolitp: string[] = [
    '<b> GU1</b><br />&bull;&nbsp;Schaafheim&nbsp;Sporthalle<br /> &bull;&nbsp;Groß-Umstadt<br /> &bull;&nbsp;' +
    'Otzberg-Lengfeld&nbsp;Bf<br /> &bull;&nbsp;Habitzheim<br /> &bull;&nbsp;Ober-Klingen&nbsp;Volkshaus',
    '<b> GU2</b><br />&bull;&nbsp;Mömlingen&nbsp;Gh.z.&nbsp;schönen&nbsp;Aussicht<br /> &bull;&nbsp;' +
    'Wald-Amorb.<br />  &bull;&nbsp;Dorndiel<br /> &bull;&nbsp;Groß-Umstadt<br /> &bull;&nbsp;Semd<br /> &bull;&nbsp;' +
    'Dieburg&nbsp;Bf',
    '<b> GU3</b><br />&bull;&nbsp;Groß-Umstadt Mitte Bf<br /> &bull;&nbsp;Heubach<br /> &bull;&nbsp;' +
    'Groß-Umstadt Wiebelsbach Bf<br /> &bull;&nbsp;Wiebelsbach Sonnenhof',
    '<b> GU4</b><br />&bull;&nbsp;Otzberg-Lengfeld<br /> &bull;&nbsp;Zipfen<br /> &bull;&nbsp;' +
    'Hering<br /> &bull;&nbsp;Ober-Nauses Wasserschloß'
  ];
  clickedLine = new Map<number, string>();
  layerStation: L.Layer = new L.Layer();
  layerRad = new Map<string, L.Layer>();
  clickedRad = new Map<number, string>();
  layerWandern = new Map<string, L.Layer>();
  clickedWandern = new Map<number, string>();
  layerPoi = new Map<string, L.Layer>();
  layerURLParam: L.GeoJSON = new L.Layer();
  URLParamPopup: L.Marker = new L.Marker();
  // lineColor: string[][] = [['gray', 'gray', 'gray', 'gray'], ['#00519E', '#6FBD96', '#7198CA', '#1E6DAB']];
  lineColor: string[][] = [['gray', 'gray', 'gray', 'gray'], ['#00519E', '#00519E', '#00519E', '#00519E']];

  // distanz = 5000;

  chromeVersion = '>=75';
  ieVersion = '>=12';
  edgeVersion = '75>=';
  operaVersion = '>=75';
  firefoxVersion = '>=66';
  safariVersion = '>=12';

  iconHst = L.icon({
    iconUrl: 'assets/HST/DADINA_Kartenicon_BUS_H.png',
    iconSize: [24, 24],
    iconAnchor: [13, 13],
    popupAnchor: [0, 0]
  });
  iconHstGray = L.icon({
    iconUrl: 'assets/HST/DADINA_Kartenicon_BUS_H-gray.png',
    iconSize: [24, 24],
    iconAnchor: [13, 13],
    popupAnchor: [0, 0]
  });

  iconBurg = L.icon({
    iconUrl: 'assets/POI/DADINA_Mark_blue_Burg.png',
    iconSize: [36, 36],
    iconAnchor: [18, 36],
    popupAnchor: [0, 0]
  });
  iconFreizeit = L.icon({
    iconUrl: 'assets/POI/DADINA_Mark_blue_Freizeit.png',
    iconSize: [36, 36],
    iconAnchor: [18, 36],
    popupAnchor: [0, 0]
  });
  iconGastro = L.icon({
    iconUrl: 'assets/POI/DADINA_Mark_blue_Gastro.png',
    iconSize: [36, 36],
    iconAnchor: [18, 36],
    popupAnchor: [0, 0]
  });
  iconKultur = L.icon({
    iconUrl: 'assets/POI/DADINA_Mark_blue_Kultur.png',
    iconSize: [36, 36],
    iconAnchor: [18, 36],
    popupAnchor: [0, 0]
  });
  iconNatur = L.icon({
    iconUrl: 'assets/POI/DADINA_Mark_blue_Natur.png',
    iconSize: [36, 36],
    iconAnchor: [18, 36],
    popupAnchor: [0, 0]
  });
  iconUnterkunft = L.icon({
    iconUrl: 'assets/POI/DADINA_Mark_blue_Unterkunft.png',
    iconSize: [36, 36],
    iconAnchor: [18, 36],
    popupAnchor: [0, 0]
  });
  iconGenuss = L.icon({
    iconUrl: 'assets/POI/DADINA_Mark_orange_Genuss.png',
    iconSize: [36, 36],
    iconAnchor: [18, 36],
    popupAnchor: [0, 0]
  });
  iconSpielplatz = L.icon({
    iconUrl: 'assets/POI/DADINA_Mark_green_Spielplatz.png',
    iconSize: [36, 36],
    iconAnchor: [18, 36],
    popupAnchor: [0, 0]
  });

  iconBad = L.icon({
    iconUrl: 'assets/POI/DADINA_Mark_blue_Bad.png',
    iconSize: [36, 36],
    iconAnchor: [18, 36],
    popupAnchor: [0, 0]
  });

  iconCamping = L.icon({
    iconUrl: 'assets/POI/DADINA_Mark_green_Camping.png',
    iconSize: [36, 36],
    iconAnchor: [18, 36],
    popupAnchor: [0, 0]
  });

  iconCaravaning = L.icon({
    iconUrl: 'assets/POI/DADINA_Mark_green_Caravaning.png',
    iconSize: [36, 36],
    iconAnchor: [18, 36],
    popupAnchor: [0, 0]
  });

  mapIcon = new Map<string, L.Icon>();

  iconDefault = L.icon({
    iconUrl: 'assets/DADINA_Point-default.png',
    iconSize: [36, 36],
    iconAnchor: [18, 36],
    popupAnchor: [0, 0]
  });


  options = {
    layers: [
      L.tileLayer('https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png', {
        maxZoom: 18,
        opacity: 0.9,
        // tslint:disable-next-line:max-line-length
        attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
     }),
      L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        opacity: 0.75,
        // tslint:disable-next-line:max-line-length
        attribution: 'Kartendaten: © <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, ' +
          'SRTM | Kartendarstellung: © <a href="http://opentopomap.org/">OpenTopoMap</a> (CC-BY-SA)'
      })
    ],
    zoom: 12,
    center: L.latLng(49.88, 8.95),
    attributionControl: false,
    zoomControl: false
  };
/*
  // Define our base layers so we can reference them multiple times
   streetMaps1 = tileLayer('https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png', {
    detectRetina: true,
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    opacity: 0.5
  });
  streetMaps2 = tileLayer('https://maps.heigit.org/openmapsurfer/tiles/roads/webmercator/{z}/{x}/{y}.png', {
    attribution: 'Imagery from <a href="http://giscience.uni-hd.de/">
                  GIScience Research Group @ University of Heidelberg</a> | Map data &copy;
                  <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    opacity: 0.5
  }); */

  // Layers control object with our two base layers and the three overlay layers
  layersControl = {
    baseLayers: {
     'Straßenkarte': this.options.layers[0],
     'Topografische Karte': this.options.layers[1]
   },
    /* overlays: {
      'Dadina Liniennetz': this.linien,
      'Dadina Haltestellen': this.haltestellen
    }, */
  };

  layersControlOptions = {
    position: 'topleft',

  };

  @Output() buttonBarClickButton: EventEmitter<string> = new EventEmitter();

  ngOnInit(): void {
    this.matomoTracker.setUserId('stb');
    this.matomoTracker.setDocumentTitle('Dadina-Map');
    this.matomoTracker.trackPageView('Map');
    this.getBrowser();

    // this.apiService.getLocationName({name: 'Fra'}, 1);
    // tslint:disable-next-line:max-line-length
    /* this.http.get('/hapi/location.name?input=frankfurt%20hauptbahnhof&accessId=295a9969-54d5-4b36-8404-a3eb2b964de5', { responseType: 'text' }).subscribe(data => {
      console.log(data);
    }); */
  }

  onMapReady(map: L.Map) {
    this.map = map;
    this.map.addControl(
      L.control.attribution({
        position: 'bottomright',
        prefix: ''
      })
    );
    this.map.removeLayer(this.options.layers[1]);
    this.initialLoadBaseMap();
    this.initialLoadLines();
    this.route.queryParams.subscribe(params => {
      if (params.kat !== undefined && params.id !== undefined) {
        const kat = params.kat;
        const id = params.id;
        this.setInitialPoi(kat, id);
      }
    });
  }

  initialLoadBaseMap() {
    this.http.get('assets/mask.geojson').subscribe((json: any) => {
      L.geoJSON(json, {
        style: {
          fillColor: 'black',
          weight: 1
        }
      })
        .addTo(this.map);
    });
  }

  initialLoadLines() {
    for (let i = 1; i < this.countLine + 1; i++) {
      // this.layerStationName[i - 1] = [];
      this.http.get('assets/LIN/LIN_' + (i + 10000) + '/lin.geojson').subscribe((json: any) => {
        this.layerLine[i - 1] = L.geoJSON(json, {
          style: {
            color: this.lineColor[0][i - 1],
            weight: 5
          },
          onEachFeature: (feature, layer) => {
            layer.bindTooltip(this.layerLineToolitp[i - 1], { sticky: true });
          }
        });
        this.map.addLayer(this.layerLine[i - 1]);
      });
    }
  }
  public setImgPoi(event: [string, string, string]) {
    if (event[0] === 'POI') {
      this.setInitialPoi(event[1], event[2]);
    }
    if (event[0] === 'Popup') {
      // b: ButtonBarComponent;

    }
  }

  setInitialPoi(kat: string, id: string) {
    let elem = document.getElementById('container-buttonbar1');
    elem.style.display = 'none';
    elem = document.getElementById('container-carousel');
    elem.style.display = 'none';
    // console.log(kat, id);
    let urlIcon;
    switch (kat) {
      case '10001': urlIcon = this.iconBurg; break;
      case '10002': urlIcon = this.iconFreizeit; break;
      case '10003': urlIcon = this.iconGastro; break;
      case '10004': urlIcon = this.iconKultur; break;
      case '10005': urlIcon = this.iconNatur; break;
      case '10006': urlIcon = this.iconUnterkunft; break;
      case '10007': urlIcon = this.iconGenuss; break;
      case '10008': urlIcon = this.iconSpielplatz; break;
      case '10009': urlIcon = this.iconBad; break;
      case '10010': urlIcon = this.iconCamping; break;
      case '10011': urlIcon = this.iconCaravaning; break;
    }
    console.log('assets/POI/POI_' + kat + '/poi.geojson');
    this.http.get('assets/POI/POI_' + kat + '/poi.geojson').subscribe((json: any) => {
      this.layerURLParam = L.geoJSON(json, {
        style: {},
        pointToLayer: (feature, layer) => {
          if (feature.properties.ID === id) {
            const geoPoi = feature.geometry;
            // GeoJson-Koordinaten vertauscht zu LatLon
            this.map.setView([geoPoi.coordinates[1], geoPoi.coordinates[0]], 16);
            // Popup für Anzeige beim Start
            const lati = geoPoi.coordinates[1];
            const longi = geoPoi.coordinates[0];
            this.URLParamPopup = L.marker([lati, longi], { icon: urlIcon });
            const popupContent = this.getPoiPopup(feature.properties);
            const popup = new L.Popup();
            popup.setContent(popupContent);
            this.URLParamPopup.addTo(this.map);
            this.URLParamPopup.bindPopup(popup);
            this.URLParamPopup.openPopup();

            const marker = L.marker(layer, { icon: urlIcon });
            return marker;
          }
        },
        onEachFeature: (feature, layer) => {
          if (feature.properties.ID === id) {
            // layer.bindTooltip(feature.properties.Name);
            layer.bindTooltip(this.getPoiPopup(feature.properties));
            layer.bindPopup(this.getPoiPopup(feature.properties));
          }
        }
      }).addTo(this.map);
    });
  }


  loadHst() {
    this.http.get('assets/HST/HST_10000/hst.geojson').subscribe((json: any) => {
      this.layerStation = L.geoJSON(json, {
        style: {},
        pointToLayer: (feature, layer) => {
          let iconHst = this.iconHstGray;
          this.clickedLine.forEach((value: string, key: number) => {
            if (feature.properties.LINE_ALL.toLowerCase().includes(value)) {
              iconHst = this.iconHst;
            }
          });
          return L.marker(layer, { icon: iconHst });
        },
        onEachFeature: (feature, layer) => {
          layer.bindTooltip(feature.properties.MAST_LABEL + '<br/>Linie: ' + feature.properties.LINE_ALL.trim());
          layer.bindPopup('<p><strong>' + feature.properties.MAST_LABEL + '</strong><br/>' +
            // tslint:disable-next-line:max-line-length
            feature.properties.LINE_ALL + '<br/><a target="_blank" rel="noopener noreferrer" href="https://www.rmv.de/auskunft/bin/jp/query.exe/dn">Verbindungssuche</a></p>');
          layer.on('click', () => {
            // let geo: any;
            const geoHst = feature.geometry;
            // GeoJson-Koordinaten vertauscht zu LatLon
            this.map.setView([geoHst.coordinates[0][1], geoHst.coordinates[0][0]], 14);
          });
        }
      }).addTo(this.map);
    });
  }

  /* handleMapZoomEnd(event: any): void {
      const zoomLevel = this.map.getZoom();
      if (zoomLevel > 12) {
        for (let i = 1; i < this.countLine + 1; i++) {
          if (!this.map.hasLayer(this.layerStation[i - 1])) {
            this.map.addLayer(this.layerStation[i - 1]);
          }
        }
      }
      if (this.map.getZoom() < 13) {
        for (let i = 1; i < this.countLine + 1; i++) {
          if (this.map.hasLayer(this.layerStation[i - 1])) {
            this.map.removeLayer(this.layerStation[i - 1]);
          }
        }
      }
      if (this.map.getZoom() < 14) {
        if (this.pois[0] != null) {
          this.map.removeLayer(this.pois[0]);
         }
      }
    } */

  public toggleRad(event: IChangeCheckboxEvent) {
    if (!this.layerRad.has(event.id) && event.checked) {
      this.http.get('assets/' + event.id + '/' + event.group + '.geojson').subscribe((json: any) => {
        const lay = L.geoJSON(json, {
          style: {
            color: event.color,
            weight: event.weight,
            dashArray: event.dashArray
          },
          pointToLayer: (feature, layer) => {
            return L.marker(layer, { icon: this.iconDefault });
          },
          onEachFeature: (feature, layer) => {
            if (feature.geometry.type === 'Point') {
              layer.bindTooltip(feature.properties.name, { sticky: true });
            }
          }
        });
        const layBg = L.geoJSON(json, {
          style: {
            weight: 30,
            opacity: '0%'
          },
          pointToLayer: (feature, layer) => {
            return null;
          },
          onEachFeature: (feature, layer) => {
            layer.bindTooltip(feature.properties.name, { sticky: true });
            if (feature.geometry.type !== 'Point' && event.group === 'tvr') {
              layer.bindPopup('<p><strong>' + feature.properties.name + '</strong><br/>' +
                ('<a target="_blank" href="assets/' + event.id + '/' + feature.properties.name + '.pdf">Detailinformationen</a></br>' +
                  // tslint:disable-next-line:max-line-length
                  '<a target="_blank" href="assets/' + event.id + '/' + feature.properties.name + '.gpx" download>Track herunterladen</a></p>')
                  .replace(/&/g, 'und'));
            }
          }
        });
        this.layerRad.set(event.id, lay);
        this.layerRad.set(event.id + 1000, layBg);
        this.layerRad.get(event.id).addTo(this.map);
        this.layerRad.get(event.id + 1000).addTo(this.map);
        this.clickedRad.set(event.index, event.id);
        this.setzeZoom(this.clickedRad, this.layerRad);
      });
    } else {
      if (event.checked) {
        if (this.map.hasLayer(this.layerRad.get(event.id))) {
          this.map.removeLayer(this.layerRad.get(event.id));
          this.map.removeLayer(this.layerRad.get(event.id + 1000));
          this.clickedRad.delete(event.index);
        } else {
          this.map.addLayer(this.layerRad.get(event.id));
          this.map.addLayer(this.layerRad.get(event.id + 1000));
          this.clickedRad.set(event.index, event.id);
        }
        this.setzeZoom(this.clickedRad, this.layerRad);
      }
    }
  }

  public toggleWandern(event: IChangeCheckboxEvent) {
    if (!this.layerWandern.has(event.id) && event.checked) {
      this.http.get('assets/' + event.id + '/' + event.group + '.geojson').subscribe((json: any) => {
        const lay = L.geoJSON(json, {
          style: {
            color: event.color,
            weight: event.weight,
            dashArray: event.dashArray
          },
          pointToLayer: (feature, layer) => {
            if (typeof feature.properties.icon === 'undefined' || feature.properties.icon === '') {
              return L.marker(layer, { icon: this.iconDefault });
            } else {
              return L.marker(layer, { icon: this.mapIcon.get(feature.properties.icon) });
            }
          },
          onEachFeature: (feature, layer) => {
            if (feature.geometry.type === 'Point') {
              layer.bindTooltip(feature.properties.name, { sticky: true });
            }
          }
        });
        const layBg = L.geoJSON(json, {
          style: {
            weight: 20,
            opacity: '0%'
          },
          pointToLayer: (feature, layer) => {
            return null;
          },
          onEachFeature: (feature, layer) => {
            layer.bindTooltip('<div style=\'line-height:0rem\'><img style=\'max-width:15rem;\' src=\'assets/' + event.id + '.jpg\'\' alt=""></div>' +
              feature.properties.name, { sticky: true });
            if (feature.geometry.type !== 'Point' && event.group === 'tvw') {
              layer.bindPopup(
                '<p><strong>' + feature.properties.name + '</strong><br/>' +
                ('<a target="_blank" href="assets/' + event.id + '/' + feature.properties.name + '.pdf">Detailinformationen</a></br>' +
                  '<a target="_blank" href="assets/' + event.id + '/' + feature.properties.name + '.gpx" download>Track herunterladen</a></p>')
                  .replace(/&/g, 'und'));
            }
          }
        },
        );
        this.layerWandern.set(event.id, lay);
        this.layerWandern.set(event.id + 1000, layBg);
        this.layerWandern.get(event.id).addTo(this.map);
        this.layerWandern.get(event.id + 1000).addTo(this.map);
        this.clickedWandern.set(event.index, event.id);
        this.setzeZoom(this.clickedWandern, this.layerWandern);
      });
    } else {
      if (event.checked) {
        if (this.map.hasLayer(this.layerWandern.get(event.id))) {
          this.map.removeLayer(this.layerWandern.get(event.id));
          this.map.removeLayer(this.layerWandern.get(event.id + 1000));
          this.clickedWandern.delete(event.index);
        } else {
          this.map.addLayer(this.layerWandern.get(event.id));
          this.map.addLayer(this.layerWandern.get(event.id + 1000));
          this.clickedWandern.set(event.index, event.id);
        }
        this.setzeZoom(this.clickedWandern, this.layerWandern);
      }
    }
  }


  public togglePoi(event: IChangeCheckboxEvent) {
    // this.testRestApi();
    console.log(event);
    this.map.removeLayer(this.layerURLParam);
    this.map.removeLayer(this.URLParamPopup);
    if (!this.layerPoi.has(event.id) && event.checked) {
      this.http.get('assets/' + event.id + '/' + event.group + '.geojson').subscribe((json: any) => {
        const lay = L.geoJSON(json, {
          style: {},
          pointToLayer: (feature, layer) => {
            return L.marker(layer, { icon: this.mapIcon.get(event.icon) });
          },
          onEachFeature: (feature, layer) => {
            // layer.bindTooltip(feature.properties.Name);
            layer.bindTooltip(this.getPoiPopup(feature.properties), {interactive: true});
            layer.bindPopup(this.getPoiPopup(feature.properties));
            layer.on('click', () => {
              if (this.map.getZoom() < 13) {
                // let geo: any;
                const geoPoi = feature.geometry;
                // GeoJson-Koordinaten vertauscht zu LatLon
                this.map.setView([geoPoi.coordinates[1], geoPoi.coordinates[0]], 13);
              }
            });
          }
        });
        this.layerPoi.set(event.id, lay);
        this.layerPoi.get(event.id).addTo(this.map);
      });
    } else {
      if (event.checked) {
        if (this.map.hasLayer(this.layerPoi.get(event.id))) {
          this.map.removeLayer(this.layerPoi.get(event.id));
        } else {
          this.map.addLayer(this.layerPoi.get(event.id));
        }
      }
    }

  }
  /* public togglePoi() {
    if (this.layerPoi.length === 0) {
      for (let i = 1; i < this.countPoi + 1; i++) {
        const icon = [this.iconBurg, this.iconFreizeit, this.iconGastro, this.iconKultur, this.iconNatur, this.iconUnterkunft];
        this.http.get('assets/POI/POI_' + (i + 10000) + '/poi.geojson').subscribe((json: any) => {
          this.layerPoi[i - 1] = L.geoJSON(json, {
            style: {},
            pointToLayer: (feature, layer) => {
              return L.marker(layer, { icon: icon[i - 1] });
            },
            onEachFeature: (feature, layer) => {
              layer.bindTooltip(feature.properties.Name);
              layer.bindPopup(this.getPoiPopup(i, feature.properties));
              layer.on('click', () => {
                if (this.map.getZoom() < 13) {
                  // let geo: any;
                  const geoPoi = feature.geometry;
                  // GeoJson-Koordinaten vertauscht zu LatLon
                  this.map.setView([geoPoi.coordinates[1], geoPoi.coordinates[0]], 13);
                }
              });
            }
          });
          this.layerPoi[i - 1].addTo(this.map);
        });
      }
    } else {
      for (let i = 0; i < this.countPoi; i++) {
        if (this.map.hasLayer(this.layerPoi[i])) {
          this.map.removeLayer(this.layerPoi[i]);
        } else {
          this.map.addLayer(this.layerPoi[i]);
        }
      }
    }
  } */

  /* public showPois() {
    if (this.pois[0] != null) {
      this.map.removeLayer(this.pois[0]);
    }
    this.http.get('assets/POI/POI_10000/poi.geojson').subscribe((json: any) => {
      this.pois[0] = L.geoJSON(json, {
        style: {
          weight: 5
        },
        onEachFeature: (feature, layer) => {
          layer.bindPopup(feature.properties.Name + '<br/>' + feature.properties.Besonderh);
          layer.bindTooltip(feature.properties.Name);
        },
        filter: (feature) => {
          return this.showPoi(this.clickedStation, feature);
        }
      });
      this.map.addLayer(this.pois[0]);
    });
  }

  public showPoi(clickedStation: Map<any, any>, feature: any): boolean {
    for (const entry of clickedStation.entries()) {
      if (this.map.distance(feature.geometry.coordinates, entry[1].coordinates[0]) > this.distanz) {
        return false;
      } else {
        return true;
      }
    }
  } */

  public updateClickedButton(event: IClickButtonEvent): void {
    switch (event.group) {
      case 'line': this.clickLine(event); break;
      case 'hst': this.clickHst(event); break;
    }
  }

  public updateChangeCheckbox(event: IChangeCheckboxEvent): void {
    switch (event.group) {
      case 'rww':
      case 'tvr': this.clickRad(event); break;
      case 'waw':
      case 'tvw': this.clickWandern(event); break;
      case 'poi': this.clickPoi(event); break;
    }
  }

  public clickLine(event: IClickButtonEvent): void {
    this.lineColor[0][event.index] = this.updateLineColor(event.index);
    this.layerLine[event.index].setStyle({ color: this.lineColor[0][event.index] });
    if (this.lineColor[0][event.index].includes('gray')) {
      this.layerLine[event.index].bringToBack();
      this.clickedLine.delete(event.index);
    } else {
      this.layerLine[event.index].bringToFront();
      this.clickedLine.set(event.index, event.id);
    }
    if (this.map.hasLayer(this.layerStation)) {
      this.map.removeLayer(this.layerStation);
      this.loadHst();
    }
    // Radio-Button-Funktion
    for (let i = 0; i < this.layerLine.length; i++) {
      if (event.index !== i) {
        this.lineColor[0][i] = 'gray';
        this.layerLine[i].setStyle({ color: 'gray' });
        this.clickedLine.delete(i);
      }
    }
    // this.layerBringToFront(this.layerRad);
    // this.layerBringToFront(this.layerWandern);
    // this.setzeZoomLine(this.clickedLine, this.layerLine);

  }

  public updateLineColor(index: number): string {
    if (this.lineColor[0][index].includes('gray')) {
      return this.lineColor[1][index];
    } else {
      return 'gray';
    }
  }

  public clickHst(event: IClickButtonEvent): void {
    if (this.map.hasLayer(this.layerStation)) {
      this.map.removeLayer(this.layerStation);
    } else {
      this.loadHst();
    }
  }

  public clickRad(event: IChangeCheckboxEvent): void {
    this.toggleRad(event);
    // this.layerBringToFront(this.layerWandern);

  }

  public clickWandern(event: IClickButtonEvent): void {
    this.toggleWandern(event);
  }

  public clickPoi(event: IClickButtonEvent): void {
    this.togglePoi(event);
  }

  public layerBringToFront(layer: any[]): void {
    for (const value of layer) {
      if (this.map.hasLayer(value)) {
        value.bringToFront();
      }
    }
  }

  public setzeZoomLine(map: Map<number, string>, arrayLine: any[]): void {
    const fg = new L.FeatureGroup();
    map.forEach((value: string, key: number) => {
      fg.addLayer(arrayLine[key]);
    });
    if (fg.getLayers().length > 0) {
      this.map.flyToBounds(fg.getBounds());
    }
  }

  public setzeZoom(map: Map<number, string>, mapLayer: Map<string, L.Layer>): void {
    const fg = new L.FeatureGroup();
    map.forEach((value: string, key: number) => {
      fg.addLayer(mapLayer.get(value));
    });
    if (fg.getLayers().length > 0) {
      this.map.flyToBounds(fg.getBounds());
    }
  }

  public getPoiPopup(properties: any): string {
    // tslint:disable-next-line:quotemark
    this.getImage(properties.ID);
    // const htmlString = "<div><img style='width: 15rem;' src='assets/POI_PIC/" + properties.ID + ".png' ><br/><br/></div>" +
    const htmlString = this.getImage(properties.ID) +
      this.getPoiProperty(properties.Name, '', 'font-size: 1.5em; font-weight: bold;', true) +
      this.getPoiProperty(properties.Kurzbeschreibung, '', 'width: 15rem', true) +
      this.getPoiProperty((properties.Straße + ' ' + properties.Hausnummer).replace('null', '').replace('null', ''), '', '', true) +
      this.getPoiProperty((properties.PLZ + ' ' + properties.Ort).replace('null', '').replace('null', ''), '', '', true) +
      this.getPoiPropertyLink(properties.Website, 'Website', '', true);
    return htmlString;
  }

  public getImage(imgID: string): string {
    if (imgID != null) {
      const bild = new Image();
      // tslint:disable-next-line:quotemark
      // tslint:disable-next-line:max-line-length
      return ('<div style=\'line-height:0rem\'><img style=\'width:15rem;\' src=\'assets/POI_PIC/' + imgID + '.jpg\'\' alt=""></div>');
    }
    return '';
  }

  public getPoiProperty(property: string, label: string, style: string, zeilenumbruch: boolean): string {
    if (property != null) {
      // tslint:disable-next-line:quotemark
      return ("<span style='" + style + "'>" + label + property + " </span>" + (zeilenumbruch ? "<br/>" : ""));
    }
    return '';
  }

  public getPoiPropertyLink(property: string, label: string, style: string, zeilenumbruch: boolean): string {
    if (property != null) {
      // tslint:disable-next-line:quotemark
      return ("<a href='" + property + "' target='_blank'><span>" + label + " </span></a>" + (zeilenumbruch ? "<br/>" : ""));
    }
    return '';
  }
  public getBrowser() {
    if (
      is.chrome(this.chromeVersion) ||
      is.firefox(this.firefoxVersion) ||
      is.safari(this.safariVersion) ||
      is.opera(this.operaVersion) ||
      is.ie(this.ieVersion) ||
      is.edge(this.edgeVersion)
    ) {
      console.log('OK');
    } else {
      console.log('nicht OK');
      const elem = document.getElementById('old-browser');
      elem.style.display = 'block';
    }
    console.log(is.getBrowser());
  }

}
