import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-popups',
  templateUrl: './popups.component.html',
  styleUrls: ['./popups.component.sass']
})

export class PopupsComponent {

  constructor(public dialog: MatDialog) {}

  openImpressum(): void {
    const dialogRef1 = this.dialog.open(ImpressumSheet, {
      height: '80%',
      width: '70%',
      panelClass: 'impressum-modalbox'
    });
    dialogRef1.afterClosed().subscribe(result => {
    });
  }
  openDatenschutz(): void {
    const dialogRef2 = this.dialog.open(DatenschutzSheet, {
      height: '80%',
      width: '70%',
      panelClass: 'datenschutz-modalbox'
    });
    dialogRef2.afterClosed().subscribe(result => {
    });
  }
  openInfo(): void {
    const dialogRef3 = this.dialog.open(InfoSheet, {
      height: '80%',
      width: '70%',
      panelClass: 'info-modalbox'
    });
    dialogRef3.afterClosed().subscribe(result => {
    });
  }
  click_x() {
  }

}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'impressum-sheet',
  templateUrl: 'impressum-sheet.html',
  styleUrls: ['./popups.component.sass']
})

// tslint:disable-next-line:component-class-suffix
export class ImpressumSheet {
  click_x() {}
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'datenschutz-sheet',
  templateUrl: 'datenschutz-sheet.html',
  styleUrls: ['./popups.component.sass']
})

// tslint:disable-next-line:component-class-suffix
export class DatenschutzSheet {
  click_x() {}
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'info-sheet',
  templateUrl: './info-sheet.html',
  styleUrls: ['./popups.component.sass']
})

// tslint:disable-next-line:component-class-suffix
export class InfoSheet {
  click_x() {}
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dadiLiner-sheet',
  templateUrl: './dadiLiner-sheet.html',
  styleUrls: ['./popups.component.sass']
})

// tslint:disable-next-line:component-class-suffix
export class DadiLinerSheet {
  click_x() {}
}
